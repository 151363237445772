import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import CssBaseline from "@mui/material/CssBaseline"
import { ThemeProvider } from "@mui/material/styles"
import theme from "../../src/theme"
import GlobalStyle from "../../src/components/global-style"
import Header from "../../src/components/header"
import Footer from "../../src/components/footer"
import styled from "styled-components"
import { Location } from "@reach/router"
import { AtlasContext } from "../../src/components/atlas-context"
import ModuleCard from "../../src/components/module-card"
import { ModuleContext } from "../../src/components/module-context"

const Wrapper = styled.div`
    *zoom: 1;
    font-family: halyard-display, sans-serif;
    font-size: 1rem;
    font-weight: 300;
    color: #333;
    min-height: 100vh;

    @media screen and (min-width: 30em) {
        font-size: 1.2rem;
    }
`

export default function TopLayout(props) {
    const [module, setModule] = React.useState()
    return (
        <Wrapper>
            <Helmet>
                <title>The Quantum Atlas</title>
                <meta
                    httpEquiv="Content-Type"
                    content="text/html; charset=utf-8"
                />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1"
                />
                <meta
                    name="description"
                    content="The Quantum Atlas is an approachable guide to quantum physics intended for non-experts. It features cartoons, animations, interactive elements and short podcasts—a multimedia approach that we hope will enrich your exploration of the quantum world."
                />
                <link
                    href="https://fonts.googleapis.com/css?family=Lora"
                    rel="stylesheet"
                />
                <link
                    rel="stylesheet"
                    href="https://use.typekit.net/too6xiv.css"
                />
                <link
                    href="https://fonts.googleapis.com/css2?family=Exo&display=swap"
                    rel="stylesheet"
                />
                <link
                    href="https://fonts.googleapis.com/css2?family=Exo+2&display=swap"
                    rel="stylesheet"
                />
            </Helmet>
            <ThemeProvider theme={theme}>
                <GlobalStyle />
                <AtlasContext.Provider value="standalone">
                    <ModuleContext.Provider value={{ module, setModule }}>
                        <Location>
                            {(props) => {
                                const { pathname } = props.location
                                const isEmbeddedGraphic =
                                    pathname.includes("embed")
                                if (!isEmbeddedGraphic) {
                                    return (
                                        <>
                                            <Header path={pathname} />
                                            <ModuleCard />
                                        </>
                                    )
                                }
                            }}
                        </Location>
                        {props.children}
                        <Location>
                            {(props) => {
                                const { pathname } = props.location
                                const isEmbeddedGraphic =
                                    pathname.includes("embed")
                                if (!isEmbeddedGraphic) {
                                    return (
                                        <>
                                            <Footer />
                                        </>
                                    )
                                }
                            }}
                        </Location>
                    </ModuleContext.Provider>
                </AtlasContext.Provider>
            </ThemeProvider>
        </Wrapper>
    )
}

TopLayout.propTypes = {
    children: PropTypes.node,
}
