import React from "react"
import styled from "styled-components"
import FacebookIcon from "@mui/icons-material/Facebook"
import InstagramIcon from "@mui/icons-material/Instagram"
import TwitterIcon from "@mui/icons-material/Twitter"
import { StaticImage } from "gatsby-plugin-image"

const OuterWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`

// const FeedbackWrapper = styled.div.attrs({
//     className: "atlasWidth",
// })`
//     width: 100%;
//     margin-right: auto;
//     margin-left: auto;
//     margin: 2rem;
// `

const IconGroupWrapper = styled.div.attrs({
    className: "atlasWidth",
})`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    @media screen and (min-width: 60em) {
        flex-wrap: nowrap;
    }

    @media screen and (max-width: 60em) {
        flex-direction: column;
    }

    align-items: center;
    justify-content: space-around;
    margin-right: auto;
    margin-left: auto;
`
const IconWrapper = styled.div`
    width: 40%;

    @media screen and (min-width: 60em) {
        width: 20%;
    }

    text-align: center;
    margin: 1rem;
`

const IconWrapper2 = styled.div`
    width: 60%;
    @media screen and (min-width: 60em) {
        width: 20%;
    }

    text-align: center;
    margin: 1rem;
`

const SocialIcons = styled.span`
    margin: 0.25rem;
`

const Comments = styled.p`
    font-size: 0.75rem;
    line-height: 1.5;
    margin-right: auto;
    margin-left: auto;
    margin-top: 0;
    margin-bottom: 0;
    color: #333333;
`

const StyledFooter = styled.div`
    width: 100%;
    background: #9bc1bc;
    border-radius: 0 50%/0 10px 0 0;
`

const Footer = () => {
    return (
        <OuterWrapper>
            <StyledFooter>
                <IconGroupWrapper>
                    <IconWrapper>
                        <a href="https://www.nsf.gov">
                            <StaticImage
                                alt="The logo of the National Science Foundation"
                                src="../assets/images/nsf_logo_black.png"
                                placeholder="tracedSVG"
                                backgroundColor="#9BC1BC"
                                style={{
                                    maxWidth: "100px",
                                    width: "100%",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    color: "#9BC1BC",
                                }}
                            />
                        </a>
                    </IconWrapper>
                    <IconWrapper>
                        <a href="https://jqi.umd.edu">
                            <StaticImage
                                alt="The logo of the Joint Quantum Institute"
                                src="../assets/images/jqiLogoGrey.png"
                                placeholder="tracedSVG"
                                style={{
                                    maxWidth: "240px",
                                    width: "100%",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                }}
                            />
                        </a>
                    </IconWrapper>
                    <IconWrapper>
                        <a href="https://iquist.illinois.edu/">
                            <StaticImage
                                alt="The logo of the Illinois Quantum Information Science Center"
                                src="../assets/images/illinoisLogo_grey.png"
                                placeholder="tracedSVG"
                                style={{
                                    maxWidth: "240px",
                                    width: "100%",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                }}
                            />
                        </a>
                    </IconWrapper>
                    <IconWrapper2>
                        <SocialIcons>
                            <a href="https://twitter.com/thequantumatlas">
                                <TwitterIcon />
                            </a>
                        </SocialIcons>
                        <SocialIcons>
                            <a href="https://www.instagram.com/thequantumatlas/">
                                <InstagramIcon />
                            </a>
                        </SocialIcons>
                        <SocialIcons>
                            <a href="https://www.facebook.com/thequantumatlas">
                                <FacebookIcon />
                            </a>
                        </SocialIcons>
                        <Comments>
                            Have some ideas? <br />
                            Want to contibute? <br />
                            <a
                                style={{ color: "#333333" }}
                                href="mailto:quantumatlas@umd.edu"
                            >
                                Contact us!
                            </a>
                        </Comments>
                    </IconWrapper2>
                </IconGroupWrapper>
            </StyledFooter>
        </OuterWrapper>
    )
}

export default Footer
