import React from "react"
import styled from "styled-components"

const AtlasLogo = styled(({ icon, maxWidth, _color, className }) => {
    let Logo = () => {
        if (icon) {
            return (
                <g>
                    <path
                        className="a"
                        d="M79.82,91.31a46.27,46.27,0,0,1-31.13,9.17,45.23,45.23,0,0,1-16.53-4.33A45.84,45.84,0,0,1,8.44,69.89,46.65,46.65,0,0,1,6,51.1a46.07,46.07,0,0,1,5.58-18.54c.86-1.39,1.68-2.82,2.6-4.17s2-2.65,3-3.89,2.26-2.39,3.43-3.54,2.49-2.14,3.76-3.18l4.1-2.73c1.42-.81,2.89-1.56,4.35-2.32s3-1.23,4.57-1.83,3.16-.91,4.73-1.35A46.21,46.21,0,0,1,61.5,9.31a47,47,0,0,1,17.38,7.52,45.77,45.77,0,0,1,18.7,30,45.29,45.29,0,0,1-.38,17.07A46.17,46.17,0,0,1,79.82,91.31Zm-.6-.8a40.6,40.6,0,0,0-4-67.45,40.53,40.53,0,1,0-10.34,74.3A41.08,41.08,0,0,0,79.22,90.51Z"
                    />
                    <path
                        className="b"
                        d="M62.82,48.54,46.59,61.8C38,45.79,29.85,29.34,21.22,13.32,35.26,24.92,48.78,36.94,62.82,48.54Z"
                    />
                    <path
                        className="c"
                        d="M48.9,63.27l15-12.12C71.33,65.41,78.3,80.06,85.75,94.33,73.31,84.1,61.34,73.49,48.9,63.27Z"
                    />
                </g>
            )
        } else {
            return (
                <g>
                    <path
                        className="a"
                        d="M19.68,81V39H9.74V36.61h9.94V16h2.48V36.61H38V39H22.16V81.32c0,6.59,1.95,10.26,9.72,10.26a33,33,0,0,0,6.38-.65v2.38a47.47,47.47,0,0,1-6.81.65C22.27,94,19.68,89.31,19.68,81Z"
                    />
                    <path
                        className="a"
                        d="M50.78,13.39h2.49V46.54C57.37,42.87,66,35.42,76.59,35.42,89,35.42,94,42,94,53.35v40H91.5v-40c0-10.37-4.64-15.56-14.91-15.56-8.85,0-16.84,5.4-23.32,11.56v44H50.78Z"
                    />
                    <path
                        className="a"
                        d="M106.4,65.12c0-20.41,12.1-29.81,23.44-29.81,11.88,0,21.71,9.72,21.71,28.73v.32H108.88v.76c0,13.5,7,27,20.63,27,9.08,0,15.34-6.37,20.09-13.07l2,1.41c-4.76,6.69-11.78,14-22,14C114.39,94.5,106.4,79.92,106.4,65.12Zm42.55-3c-.64-15.88-8.74-24.41-19.11-24.41-9.83,0-19.77,8-20.85,24.41Z"
                    />
                    <path
                        className="a"
                        d="M287.08,77.76V36.61h5.18v40.5C292.26,85.75,296,90,304.68,90c7.89,0,14.47-4.1,21.49-10.8V36.61h5.19v56.7h-5.19v-9.4c-6.26,5.94-13.6,10.59-21.92,10.59C292.48,94.5,287.08,88,287.08,77.76Z"
                    />
                    <path
                        className="a"
                        d="M344,80.24c0-10.8,9.18-18.9,39-23.87V52.92c0-8.65-4.53-13-12.31-13-9.4,0-15.66,3.68-22,9.4l-3-3.24c7-6.48,14.26-10.69,25.17-10.69,11.77,0,17.39,6.69,17.39,17.17V79.27c0,6.91.43,11.12,1.62,14h-5.51a27.54,27.54,0,0,1-1.3-8.43c-6.91,5.94-14.47,9.4-23,9.4C349.61,94.28,344,88.88,344,80.24Zm39-.43V60.26c-27.65,4.86-33.8,11.66-33.8,19.66,0,6.37,4.21,9.82,11.23,9.82C368.83,89.74,376.5,86.07,383,79.81Z"
                    />
                    <path
                        className="a"
                        d="M403.5,36.61h5.18V46c6.27-5.94,13.61-10.58,21.93-10.58,11.77,0,17.17,6.48,17.17,16.74V93.31H442.6V52.81c0-8.64-3.78-12.86-12.42-12.86-7.89,0-14.48,4.11-21.5,10.8V93.31H403.5Z"
                    />
                    <path
                        className="a"
                        d="M466.9,80.67V41.25H457V36.61h9.94V16h5.18V36.61h14.8v4.64h-14.8V79.92c0,6,2.05,9.5,9.18,9.5a19.83,19.83,0,0,0,5.94-.76v4.86a36.41,36.41,0,0,1-6.37.54C471.43,94.06,466.9,90.28,466.9,80.67Z"
                    />
                    <path
                        className="a"
                        d="M498.32,77.76V36.61h5.19v40.5c0,8.64,3.78,12.85,12.42,12.85,7.88,0,14.47-4.1,21.49-10.8V36.61h5.18v56.7h-5.18v-9.4c-6.26,5.94-13.61,10.59-21.92,10.59C503.72,94.5,498.32,88,498.32,77.76Z"
                    />
                    <path
                        className="a"
                        d="M558.59,36.61h5.18V46c6.27-5.94,13-10.58,21.28-10.58,9.61,0,14.15,4.43,15.77,10.69,6.48-5.94,13.71-10.69,21.92-10.69,11.66,0,16.42,6.48,16.42,16.74V93.31H634V52.81C634,44.17,630.84,40,622.2,40c-7.45,0-13.72,4.11-20.74,10.8V93.31h-5.18V52.81c0-8.64-3-12.86-11.77-12.86-7.35,0-13.72,4.11-20.74,10.8V93.31h-5.18Z"
                    />
                    <path
                        className="a"
                        d="M672.53,80.89c0-13.29,14.14-20.2,40.06-24.09V52.16c0-8.75-4.21-14.37-13.71-14.37a31.94,31.94,0,0,0-22.57,9.62l-1.62-1.62a33.58,33.58,0,0,1,24.19-10.37c11.23,0,16.2,6.37,16.2,16.52V81c0,7.23.32,9.18,1.29,12.31h-2.48c-1-2.7-1.19-4.75-1.3-10-4.75,5-13.93,11.45-24.3,11.45C678.36,94.71,672.53,89.85,672.53,80.89Zm40.06-.54V59C684.08,63.28,675,71.06,675,80.89c0,7.45,5,11.45,13.28,11.45C698.66,92.34,708.38,85.21,712.59,80.35Z"
                    />
                    <path
                        className="a"
                        d="M734.63,81V39h-9.94V36.61h9.94V16h2.48V36.61H753V39H737.11V81.32c0,6.59,1.94,10.26,9.72,10.26a32.79,32.79,0,0,0,6.37-.65v2.38a47.33,47.33,0,0,1-6.8.65C737.22,94,734.63,89.31,734.63,81Z"
                    />
                    <path className="a" d="M766.27,13.39h2.48V93.31h-2.48Z" />
                    <path
                        className="a"
                        d="M782.47,80.89c0-13.29,14.15-20.2,40.07-24.09V52.16c0-8.75-4.22-14.37-13.72-14.37a31.94,31.94,0,0,0-22.57,9.62l-1.62-1.62a33.6,33.6,0,0,1,24.19-10.37c11.23,0,16.2,6.37,16.2,16.52V81c0,7.23.33,9.18,1.3,12.31h-2.49c-1-2.7-1.18-4.75-1.29-10-4.76,5-13.94,11.45-24.3,11.45C788.3,94.71,782.47,89.85,782.47,80.89Zm40.07-.54V59C794,63.28,785,71.06,785,80.89c0,7.45,5,11.45,13.29,11.45C808.6,92.34,818.32,85.21,822.54,80.35Z"
                    />
                    <path
                        className="a"
                        d="M837.33,84.13l1.95-1.51c4.1,5.18,10,9.5,18.68,9.5,11,0,18.15-5,18.15-13.28,0-8.75-6.81-11.67-18.69-14.69S839.06,58.1,839.06,48.7c0-7.34,6.16-13.28,17.6-13.28,10.05,0,16,4.21,19.77,8.53l-1.95,1.51c-3.56-4.1-9.07-7.67-17.82-7.67-10.69,0-15.12,5.3-15.12,10.91,0,8.1,6.16,10.48,16.64,13.18,12,3,20.41,6.16,20.41,17,0,9.61-8.32,15.66-20.63,15.66A25,25,0,0,1,837.33,84.13Z"
                    />
                    <path
                        className="a"
                        d="M258,89.29a46.22,46.22,0,0,1-31.13,9.17,45.23,45.23,0,0,1-16.53-4.33,45.76,45.76,0,0,1-23.72-26.26,46.65,46.65,0,0,1-2.43-18.79,46,46,0,0,1,5.58-18.53c.86-1.4,1.68-2.82,2.6-4.18s2-2.65,3-3.89,2.26-2.39,3.43-3.54,2.49-2.14,3.76-3.18l4.1-2.73c1.43-.81,2.89-1.56,4.35-2.32s3.05-1.23,4.57-1.83,3.16-.91,4.73-1.34a46.42,46.42,0,0,1,19.35-.25,47,47,0,0,1,17.37,7.52,45.84,45.84,0,0,1,18.71,30,45.5,45.5,0,0,1-.39,17.07A46.17,46.17,0,0,1,258,89.29Zm-.6-.8A40.58,40.58,0,0,0,238.5,15.9a40.54,40.54,0,1,0,4.57,79.44A41,41,0,0,0,257.42,88.49Z"
                    />
                    <path
                        className="b"
                        d="M241,46.52,224.79,59.79c-8.63-16-16.74-32.47-25.37-48.49C213.46,22.9,227,34.92,241,46.52Z"
                    />
                    <path
                        className="c"
                        d="M227.1,61.25l15-12.12C249.53,63.39,256.5,78.05,264,92.31,251.51,82.09,239.54,71.47,227.1,61.25Z"
                    />
                </g>
            )
        }
    }

    return (
        <svg
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            viewBox={icon ? "0 0 104 108" : "0 0 887.44 109.57"}
            style={{ maxWidth: maxWidth }}
        >
            <Logo />
        </svg>
    )
})`
    .a {
        fill: ${(props) => (props.color ? props.color : "#fff")};
    }

    .b {
        stroke: ${(props) => (props.color ? " #ed6a5a" : "#c6c6c6")};
        stroke-width: 2px;
        fill: none;
        stroke-miterlimit: 10;
    }

    .c {
        fill: none;
        stroke-miterlimit: 10;
        stroke: ${(props) => (props.color ? props.color : "#fff")};
        stroke-width: 3.39px;
    }
`

export default AtlasLogo
