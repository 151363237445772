import * as React from "react"
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import Menu from "@mui/material/Menu"
import Container from "@mui/material/Container"
import MenuItem from "@mui/material/MenuItem"
import MoreIcon from "@mui/icons-material/MoreVert"
import { Link } from "gatsby"

import AtlasLogo from "../components/atlas-logo"

const Header = (props) => {
    const { path } = props
    const [anchorElNav, setAnchorElNav] = React.useState(null)

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget)
    }

    const handleCloseNavMenu = () => {
        setAnchorElNav(null)
    }

    return (
        <AppBar position="static" color="transparent" sx={{ boxShadow: 0 }}>
            <Container maxWidth="100%">
                <Toolbar disableGutters>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{
                            mr: 2,
                            display: { xs: "none", md: "block" },
                            maxWidth: "300px",
                            width: "100%",
                        }}
                    >
                        <Link to="/">
                            <AtlasLogo color="black" />
                        </Link>
                    </Typography>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{
                            mr: 2,
                            display: { xs: "flex", md: "none" },
                            maxWidth: "40px",
                        }}
                    >
                        <Link to="/">
                            <AtlasLogo icon color="black" />
                        </Link>
                    </Typography>
                    <Box
                        sx={{
                            flexGrow: 1,
                            justifyContent: "end",
                            display: { xs: "flex", md: "none" },
                        }}
                    >
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MoreIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: "block", md: "none" },
                            }}
                        >
                            {/* {pages.map((page) => (
                                <MenuItem
                                    key={page}
                                    onClick={handleCloseNavMenu}
                                >
                                    <Typography textAlign="center">
                                        {page}
                                    </Typography>
                                </MenuItem>
                            ))} */}
                            <MenuItem
                                key={"start"}
                                onClick={handleCloseNavMenu}
                            >
                                <Link
                                    to="/start"
                                    style={{ textDecoration: "none" }}
                                >
                                    <Typography
                                        textAlign="center"
                                        sx={{ color: "black" }}
                                    >
                                        Get Started
                                    </Typography>
                                </Link>
                            </MenuItem>
                            <MenuItem
                                key={"entry-list"}
                                onClick={handleCloseNavMenu}
                            >
                                <Link
                                    to="/entry-list"
                                    style={{ textDecoration: "none" }}
                                >
                                    <Typography
                                        textAlign="center"
                                        sx={{ color: "black" }}
                                    >
                                        Entries
                                    </Typography>
                                </Link>
                            </MenuItem>
                            <MenuItem
                                key={"gallery"}
                                onClick={handleCloseNavMenu}
                            >
                                <Link
                                    to="/gallery"
                                    style={{ textDecoration: "none" }}
                                >
                                    <Typography
                                        textAlign="center"
                                        sx={{ color: "black" }}
                                    >
                                        Gallery
                                    </Typography>
                                </Link>
                            </MenuItem>
                            <MenuItem
                                key={"About"}
                                onClick={handleCloseNavMenu}
                            >
                                <Link
                                    to="/about"
                                    style={{ textDecoration: "none" }}
                                >
                                    <Typography
                                        textAlign="center"
                                        sx={{ color: "black" }}
                                    >
                                        About
                                    </Typography>
                                </Link>
                            </MenuItem>
                        </Menu>
                    </Box>
                    <Box
                        sx={{
                            flexGrow: 1,
                            justifyContent: "end",
                            display: { xs: "none", md: "flex" },
                        }}
                    >
                        {/* {pages.map((page) => (
                            <Button
                                key={page}
                                onClick={handleCloseNavMenu}
                                sx={{ my: 2, color: "black", display: "block" }}
                            >
                                {page}
                            </Button>
                        ))} */}
                        <Link to="/start" style={{ textDecoration: "none" }}>
                            <Typography
                                sx={{
                                    fontSize: "1rem",
                                    lineHeight: "35px",
                                    margin: "2px 8px",
                                    color: "black",
                                    fontWeight: "100",
                                    textDecoration: "none",
                                    fontSize: { md: "1.2rem", lg: "1.6rem" },
                                    fontFamily: "halyard-display, sans-serif",
                                    background: path.includes("/start")
                                        ? "linear-gradient(183deg,rgba(255,255,255,0) 54%, rgba(237,106,90, 0.5) 50%)"
                                        : "none",
                                }}
                            >
                                Get Started
                            </Typography>
                        </Link>
                        <Link
                            to="/entry-list"
                            style={{ textDecoration: "none" }}
                        >
                            <Typography
                                sx={{
                                    fontSize: "1rem",
                                    lineHeight: "35px",
                                    margin: "2px 8px",
                                    color: "black",
                                    fontWeight: "100",
                                    textDecoration: "none",
                                    fontSize: { md: "1.2rem", lg: "1.6rem" },
                                    fontFamily: "halyard-display, sans-serif",
                                    background: path.includes("/entry-list")
                                        ? "linear-gradient(183deg,rgba(255,255,255,0) 54%, rgba(237,106,90, 0.5) 50%)"
                                        : "none",
                                }}
                            >
                                Entries
                            </Typography>
                        </Link>
                        <Link to="/gallery" style={{ textDecoration: "none" }}>
                            <Typography
                                sx={{
                                    fontSize: "1rem",
                                    lineHeight: "35px",
                                    margin: "2px 8px",
                                    color: "black",
                                    fontWeight: "100",
                                    textDecoration: "none",
                                    fontSize: { md: "1.2rem", lg: "1.6rem" },
                                    fontFamily: "halyard-display, sans-serif",
                                    background: path.includes("/gallery")
                                        ? "linear-gradient(183deg,rgba(255,255,255,0) 54%, rgba(237,106,90, 0.5) 50%)"
                                        : "none",
                                }}
                            >
                                Gallery
                            </Typography>
                        </Link>
                        <Link to="/about" style={{ textDecoration: "none" }}>
                            <Typography
                                sx={{
                                    fontSize: "1rem",
                                    lineHeight: "35px",
                                    margin: "2px 8px",
                                    color: "black",
                                    fontWeight: "100",
                                    textDecoration: "none",
                                    fontSize: { md: "1.2rem", lg: "1.6rem" },
                                    fontFamily: "halyard-display, sans-serif",
                                    background: path.includes("/about")
                                        ? "linear-gradient(183deg,rgba(255,255,255,0) 54%, rgba(237,106,90, 0.5) 50%)"
                                        : "none",
                                }}
                            >
                                About
                            </Typography>
                        </Link>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    )
}
export default Header
