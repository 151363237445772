exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-mdx": () => import("./../../../src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-pages-entry-list-js": () => import("./../../../src/pages/entry-list.js" /* webpackChunkName: "component---src-pages-entry-list-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-start-mdx": () => import("./../../../src/pages/start.mdx" /* webpackChunkName: "component---src-pages-start-mdx" */),
  "component---src-templates-entry-template-js-content-file-path-opt-build-repo-src-entries-entanglement-mdx": () => import("./../../../src/templates/entry-template.js?__contentFilePath=/opt/build/repo/src/entries/entanglement.mdx" /* webpackChunkName: "component---src-templates-entry-template-js-content-file-path-opt-build-repo-src-entries-entanglement-mdx" */),
  "component---src-templates-entry-template-js-content-file-path-opt-build-repo-src-entries-error-correction-mdx": () => import("./../../../src/templates/entry-template.js?__contentFilePath=/opt/build/repo/src/entries/error-correction.mdx" /* webpackChunkName: "component---src-templates-entry-template-js-content-file-path-opt-build-repo-src-entries-error-correction-mdx" */),
  "component---src-templates-entry-template-js-content-file-path-opt-build-repo-src-entries-laser-cooling-mdx": () => import("./../../../src/templates/entry-template.js?__contentFilePath=/opt/build/repo/src/entries/laser-cooling.mdx" /* webpackChunkName: "component---src-templates-entry-template-js-content-file-path-opt-build-repo-src-entries-laser-cooling-mdx" */),
  "component---src-templates-entry-template-js-content-file-path-opt-build-repo-src-entries-measurement-mdx": () => import("./../../../src/templates/entry-template.js?__contentFilePath=/opt/build/repo/src/entries/measurement.mdx" /* webpackChunkName: "component---src-templates-entry-template-js-content-file-path-opt-build-repo-src-entries-measurement-mdx" */),
  "component---src-templates-entry-template-js-content-file-path-opt-build-repo-src-entries-measuring-polarization-mdx": () => import("./../../../src/templates/entry-template.js?__contentFilePath=/opt/build/repo/src/entries/measuring-polarization.mdx" /* webpackChunkName: "component---src-templates-entry-template-js-content-file-path-opt-build-repo-src-entries-measuring-polarization-mdx" */),
  "component---src-templates-entry-template-js-content-file-path-opt-build-repo-src-entries-photons-mdx": () => import("./../../../src/templates/entry-template.js?__contentFilePath=/opt/build/repo/src/entries/photons.mdx" /* webpackChunkName: "component---src-templates-entry-template-js-content-file-path-opt-build-repo-src-entries-photons-mdx" */),
  "component---src-templates-entry-template-js-content-file-path-opt-build-repo-src-entries-platforms-mdx": () => import("./../../../src/templates/entry-template.js?__contentFilePath=/opt/build/repo/src/entries/platforms.mdx" /* webpackChunkName: "component---src-templates-entry-template-js-content-file-path-opt-build-repo-src-entries-platforms-mdx" */),
  "component---src-templates-entry-template-js-content-file-path-opt-build-repo-src-entries-polarization-mdx": () => import("./../../../src/templates/entry-template.js?__contentFilePath=/opt/build/repo/src/entries/polarization.mdx" /* webpackChunkName: "component---src-templates-entry-template-js-content-file-path-opt-build-repo-src-entries-polarization-mdx" */),
  "component---src-templates-entry-template-js-content-file-path-opt-build-repo-src-entries-quantum-classical-mdx": () => import("./../../../src/templates/entry-template.js?__contentFilePath=/opt/build/repo/src/entries/quantum-classical.mdx" /* webpackChunkName: "component---src-templates-entry-template-js-content-file-path-opt-build-repo-src-entries-quantum-classical-mdx" */),
  "component---src-templates-entry-template-js-content-file-path-opt-build-repo-src-entries-quantum-computing-mdx": () => import("./../../../src/templates/entry-template.js?__contentFilePath=/opt/build/repo/src/entries/quantum-computing.mdx" /* webpackChunkName: "component---src-templates-entry-template-js-content-file-path-opt-build-repo-src-entries-quantum-computing-mdx" */),
  "component---src-templates-entry-template-js-content-file-path-opt-build-repo-src-entries-quantum-cryptography-mdx": () => import("./../../../src/templates/entry-template.js?__contentFilePath=/opt/build/repo/src/entries/quantum-cryptography.mdx" /* webpackChunkName: "component---src-templates-entry-template-js-content-file-path-opt-build-repo-src-entries-quantum-cryptography-mdx" */),
  "component---src-templates-entry-template-js-content-file-path-opt-build-repo-src-entries-quantum-states-mdx": () => import("./../../../src/templates/entry-template.js?__contentFilePath=/opt/build/repo/src/entries/quantum-states.mdx" /* webpackChunkName: "component---src-templates-entry-template-js-content-file-path-opt-build-repo-src-entries-quantum-states-mdx" */),
  "component---src-templates-entry-template-js-content-file-path-opt-build-repo-src-entries-qubit-mdx": () => import("./../../../src/templates/entry-template.js?__contentFilePath=/opt/build/repo/src/entries/qubit.mdx" /* webpackChunkName: "component---src-templates-entry-template-js-content-file-path-opt-build-repo-src-entries-qubit-mdx" */),
  "component---src-templates-entry-template-js-content-file-path-opt-build-repo-src-entries-spin-mdx": () => import("./../../../src/templates/entry-template.js?__contentFilePath=/opt/build/repo/src/entries/spin.mdx" /* webpackChunkName: "component---src-templates-entry-template-js-content-file-path-opt-build-repo-src-entries-spin-mdx" */),
  "component---src-templates-entry-template-js-content-file-path-opt-build-repo-src-entries-superconductors-mdx": () => import("./../../../src/templates/entry-template.js?__contentFilePath=/opt/build/repo/src/entries/superconductors.mdx" /* webpackChunkName: "component---src-templates-entry-template-js-content-file-path-opt-build-repo-src-entries-superconductors-mdx" */),
  "component---src-templates-entry-template-js-content-file-path-opt-build-repo-src-entries-superposition-mdx": () => import("./../../../src/templates/entry-template.js?__contentFilePath=/opt/build/repo/src/entries/superposition.mdx" /* webpackChunkName: "component---src-templates-entry-template-js-content-file-path-opt-build-repo-src-entries-superposition-mdx" */),
  "component---src-templates-entry-template-js-content-file-path-opt-build-repo-src-entries-teleportation-mdx": () => import("./../../../src/templates/entry-template.js?__contentFilePath=/opt/build/repo/src/entries/teleportation.mdx" /* webpackChunkName: "component---src-templates-entry-template-js-content-file-path-opt-build-repo-src-entries-teleportation-mdx" */),
  "component---src-templates-entry-template-js-content-file-path-opt-build-repo-src-entries-uncertainty-principle-mdx": () => import("./../../../src/templates/entry-template.js?__contentFilePath=/opt/build/repo/src/entries/uncertainty-principle.mdx" /* webpackChunkName: "component---src-templates-entry-template-js-content-file-path-opt-build-repo-src-entries-uncertainty-principle-mdx" */),
  "component---src-templates-gallery-template-js": () => import("./../../../src/templates/gallery-template.js" /* webpackChunkName: "component---src-templates-gallery-template-js" */),
  "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-atom-photon-atom-photon-mdx": () => import("./../../../src/templates/graphic-template.js?__contentFilePath=/opt/build/repo/src/graphics/atom-photon/atom-photon.mdx" /* webpackChunkName: "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-atom-photon-atom-photon-mdx" */),
  "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-cheese-grater-cheese-grater-mdx": () => import("./../../../src/templates/graphic-template.js?__contentFilePath=/opt/build/repo/src/graphics/cheese-grater/cheese-grater.mdx" /* webpackChunkName: "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-cheese-grater-cheese-grater-mdx" */),
  "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-classical-spacecraft-classical-spacecraft-mdx": () => import("./../../../src/templates/graphic-template.js?__contentFilePath=/opt/build/repo/src/graphics/classical-spacecraft/classical-spacecraft.mdx" /* webpackChunkName: "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-classical-spacecraft-classical-spacecraft-mdx" */),
  "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-complementary-properties-complementary-properties-mdx": () => import("./../../../src/templates/graphic-template.js?__contentFilePath=/opt/build/repo/src/graphics/complementary-properties/complementary-properties.mdx" /* webpackChunkName: "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-complementary-properties-complementary-properties-mdx" */),
  "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-double-slit-double-slit-mdx": () => import("./../../../src/templates/graphic-template.js?__contentFilePath=/opt/build/repo/src/graphics/double-slit/double-slit.mdx" /* webpackChunkName: "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-double-slit-double-slit-mdx" */),
  "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-electron-orbitals-electron-orbitals-mdx": () => import("./../../../src/templates/graphic-template.js?__contentFilePath=/opt/build/repo/src/graphics/electron-orbitals/electron-orbitals.mdx" /* webpackChunkName: "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-electron-orbitals-electron-orbitals-mdx" */),
  "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-entangled-photons-entangled-photons-mdx": () => import("./../../../src/templates/graphic-template.js?__contentFilePath=/opt/build/repo/src/graphics/entangled-photons/entangled-photons.mdx" /* webpackChunkName: "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-entangled-photons-entangled-photons-mdx" */),
  "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-entanglement-knots-entanglement-knots-mdx": () => import("./../../../src/templates/graphic-template.js?__contentFilePath=/opt/build/repo/src/graphics/entanglement-knots/entanglement-knots.mdx" /* webpackChunkName: "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-entanglement-knots-entanglement-knots-mdx" */),
  "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-entanglement-movie-entanglement-movie-mdx": () => import("./../../../src/templates/graphic-template.js?__contentFilePath=/opt/build/repo/src/graphics/entanglement-movie/entanglement-movie.mdx" /* webpackChunkName: "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-entanglement-movie-entanglement-movie-mdx" */),
  "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-exponential-growth-exponential-growth-mdx": () => import("./../../../src/templates/graphic-template.js?__contentFilePath=/opt/build/repo/src/graphics/exponential-growth/exponential-growth.mdx" /* webpackChunkName: "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-exponential-growth-exponential-growth-mdx" */),
  "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-ion-trap-qubit-ion-trap-qubit-mdx": () => import("./../../../src/templates/graphic-template.js?__contentFilePath=/opt/build/repo/src/graphics/ion-trap-qubit/ion-trap-qubit.mdx" /* webpackChunkName: "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-ion-trap-qubit-ion-trap-qubit-mdx" */),
  "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-landing-page-polygon-landing-page-polygon-mdx": () => import("./../../../src/templates/graphic-template.js?__contentFilePath=/opt/build/repo/src/graphics/landing-page-polygon/landing-page-polygon.mdx" /* webpackChunkName: "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-landing-page-polygon-landing-page-polygon-mdx" */),
  "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-measurement-brick-measurement-brick-mdx": () => import("./../../../src/templates/graphic-template.js?__contentFilePath=/opt/build/repo/src/graphics/measurement-brick/measurement-brick.mdx" /* webpackChunkName: "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-measurement-brick-measurement-brick-mdx" */),
  "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-measurement-cartoon-measurement-cartoon-mdx": () => import("./../../../src/templates/graphic-template.js?__contentFilePath=/opt/build/repo/src/graphics/measurement-cartoon/measurement-cartoon.mdx" /* webpackChunkName: "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-measurement-cartoon-measurement-cartoon-mdx" */),
  "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-measuring-polarization-measuring-polarization-mdx": () => import("./../../../src/templates/graphic-template.js?__contentFilePath=/opt/build/repo/src/graphics/measuring-polarization/measuring-polarization.mdx" /* webpackChunkName: "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-measuring-polarization-measuring-polarization-mdx" */),
  "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-photon-batman-photon-batman-mdx": () => import("./../../../src/templates/graphic-template.js?__contentFilePath=/opt/build/repo/src/graphics/photon-batman/photon-batman.mdx" /* webpackChunkName: "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-photon-batman-photon-batman-mdx" */),
  "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-photon-polarization-qubit-photon-polarization-qubit-mdx": () => import("./../../../src/templates/graphic-template.js?__contentFilePath=/opt/build/repo/src/graphics/photon-polarization-qubit/photon-polarization-qubit.mdx" /* webpackChunkName: "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-photon-polarization-qubit-photon-polarization-qubit-mdx" */),
  "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-polarization-mail-slot-polarization-mail-slot-mdx": () => import("./../../../src/templates/graphic-template.js?__contentFilePath=/opt/build/repo/src/graphics/polarization-mail-slot/polarization-mail-slot.mdx" /* webpackChunkName: "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-polarization-mail-slot-polarization-mail-slot-mdx" */),
  "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-polarization-plots-polarization-plots-mdx": () => import("./../../../src/templates/graphic-template.js?__contentFilePath=/opt/build/repo/src/graphics/polarization-plots/polarization-plots.mdx" /* webpackChunkName: "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-polarization-plots-polarization-plots-mdx" */),
  "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-polarization-sunglasses-polarization-sunglasses-mdx": () => import("./../../../src/templates/graphic-template.js?__contentFilePath=/opt/build/repo/src/graphics/polarization-sunglasses/polarization-sunglasses.mdx" /* webpackChunkName: "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-polarization-sunglasses-polarization-sunglasses-mdx" */),
  "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-qec-fridge-qec-fridge-mdx": () => import("./../../../src/templates/graphic-template.js?__contentFilePath=/opt/build/repo/src/graphics/qec-fridge/qec-fridge.mdx" /* webpackChunkName: "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-qec-fridge-qec-fridge-mdx" */),
  "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-quantum-bubbles-quantum-bubbles-mdx": () => import("./../../../src/templates/graphic-template.js?__contentFilePath=/opt/build/repo/src/graphics/quantum-bubbles/quantum-bubbles.mdx" /* webpackChunkName: "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-quantum-bubbles-quantum-bubbles-mdx" */),
  "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-quantum-classical-banner-quantum-classical-banner-mdx": () => import("./../../../src/templates/graphic-template.js?__contentFilePath=/opt/build/repo/src/graphics/quantum-classical-banner/quantum-classical-banner.mdx" /* webpackChunkName: "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-quantum-classical-banner-quantum-classical-banner-mdx" */),
  "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-quantum-classical-waves-quantum-classical-waves-mdx": () => import("./../../../src/templates/graphic-template.js?__contentFilePath=/opt/build/repo/src/graphics/quantum-classical-waves/quantum-classical-waves.mdx" /* webpackChunkName: "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-quantum-classical-waves-quantum-classical-waves-mdx" */),
  "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-quantum-computer-apps-quantum-computer-apps-mdx": () => import("./../../../src/templates/graphic-template.js?__contentFilePath=/opt/build/repo/src/graphics/quantum-computer-apps/quantum-computer-apps.mdx" /* webpackChunkName: "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-quantum-computer-apps-quantum-computer-apps-mdx" */),
  "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-quantum-computer-banner-quantum-computing-banner-mdx": () => import("./../../../src/templates/graphic-template.js?__contentFilePath=/opt/build/repo/src/graphics/quantum-computer-banner/quantum-computing-banner.mdx" /* webpackChunkName: "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-quantum-computer-banner-quantum-computing-banner-mdx" */),
  "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-qubit-banner-qubit-banner-mdx": () => import("./../../../src/templates/graphic-template.js?__contentFilePath=/opt/build/repo/src/graphics/qubit-banner/qubit-banner.mdx" /* webpackChunkName: "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-qubit-banner-qubit-banner-mdx" */),
  "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-qubit-infographic-qubit-infographic-mdx": () => import("./../../../src/templates/graphic-template.js?__contentFilePath=/opt/build/repo/src/graphics/qubit-infographic/qubit-infographic.mdx" /* webpackChunkName: "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-qubit-infographic-qubit-infographic-mdx" */),
  "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-ramp-vs-stairs-ramp-vs-stairs-mdx": () => import("./../../../src/templates/graphic-template.js?__contentFilePath=/opt/build/repo/src/graphics/ramp-vs-stairs/ramp-vs-stairs.mdx" /* webpackChunkName: "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-ramp-vs-stairs-ramp-vs-stairs-mdx" */),
  "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-rolston-probability-rolston-probability-mdx": () => import("./../../../src/templates/graphic-template.js?__contentFilePath=/opt/build/repo/src/graphics/rolston-probability/rolston-probability.mdx" /* webpackChunkName: "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-rolston-probability-rolston-probability-mdx" */),
  "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-rolston-superposition-rolston-superposition-mdx": () => import("./../../../src/templates/graphic-template.js?__contentFilePath=/opt/build/repo/src/graphics/rolston-superposition/rolston-superposition.mdx" /* webpackChunkName: "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-rolston-superposition-rolston-superposition-mdx" */),
  "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-spin-ball-pit-spin-ball-pit-mdx": () => import("./../../../src/templates/graphic-template.js?__contentFilePath=/opt/build/repo/src/graphics/spin-ball-pit/spin-ball-pit.mdx" /* webpackChunkName: "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-spin-ball-pit-spin-ball-pit-mdx" */),
  "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-spin-earth-spin-earth-mdx": () => import("./../../../src/templates/graphic-template.js?__contentFilePath=/opt/build/repo/src/graphics/spin-earth/spin-earth.mdx" /* webpackChunkName: "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-spin-earth-spin-earth-mdx" */),
  "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-stern-gerlach-stern-gerlach-mdx": () => import("./../../../src/templates/graphic-template.js?__contentFilePath=/opt/build/repo/src/graphics/stern-gerlach/stern-gerlach.mdx" /* webpackChunkName: "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-stern-gerlach-stern-gerlach-mdx" */),
  "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-superconductor-qubit-superconductor-qubit-mdx": () => import("./../../../src/templates/graphic-template.js?__contentFilePath=/opt/build/repo/src/graphics/superconductor-qubit/superconductor-qubit.mdx" /* webpackChunkName: "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-superconductor-qubit-superconductor-qubit-mdx" */),
  "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-superposition-banana-superposition-banana-mdx": () => import("./../../../src/templates/graphic-template.js?__contentFilePath=/opt/build/repo/src/graphics/superposition-banana/superposition-banana.mdx" /* webpackChunkName: "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-superposition-banana-superposition-banana-mdx" */),
  "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-superposition-guitar-superposition-guitar-mdx": () => import("./../../../src/templates/graphic-template.js?__contentFilePath=/opt/build/repo/src/graphics/superposition-guitar/superposition-guitar.mdx" /* webpackChunkName: "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-superposition-guitar-superposition-guitar-mdx" */),
  "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-teleportation-banner-teleportation-banner-mdx": () => import("./../../../src/templates/graphic-template.js?__contentFilePath=/opt/build/repo/src/graphics/teleportation-banner/teleportation-banner.mdx" /* webpackChunkName: "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-teleportation-banner-teleportation-banner-mdx" */),
  "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-teleportation-kirk-teleportation-kirk-mdx": () => import("./../../../src/templates/graphic-template.js?__contentFilePath=/opt/build/repo/src/graphics/teleportation-kirk/teleportation-kirk.mdx" /* webpackChunkName: "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-teleportation-kirk-teleportation-kirk-mdx" */),
  "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-uncertainty-rectangles-uncertainty-rectangles-mdx": () => import("./../../../src/templates/graphic-template.js?__contentFilePath=/opt/build/repo/src/graphics/uncertainty-rectangles/uncertainty-rectangles.mdx" /* webpackChunkName: "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-uncertainty-rectangles-uncertainty-rectangles-mdx" */),
  "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-uncertainty-seesaw-uncertainty-seesaw-mdx": () => import("./../../../src/templates/graphic-template.js?__contentFilePath=/opt/build/repo/src/graphics/uncertainty-seesaw/uncertainty-seesaw.mdx" /* webpackChunkName: "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-uncertainty-seesaw-uncertainty-seesaw-mdx" */),
  "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-wave-polarizer-wave-polarizer-mdx": () => import("./../../../src/templates/graphic-template.js?__contentFilePath=/opt/build/repo/src/graphics/wave-polarizer/wave-polarizer.mdx" /* webpackChunkName: "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-wave-polarizer-wave-polarizer-mdx" */),
  "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-william-spin-william-spin-mdx": () => import("./../../../src/templates/graphic-template.js?__contentFilePath=/opt/build/repo/src/graphics/william-spin/william-spin.mdx" /* webpackChunkName: "component---src-templates-graphic-template-js-content-file-path-opt-build-repo-src-graphics-william-spin-william-spin-mdx" */)
}

