import * as React from "react"
import { styled } from "@mui/material/styles"
import Card from "@mui/material/Card"
import CardHeader from "@mui/material/CardHeader"
import CardMedia from "@mui/material/CardMedia"
import CardContent from "@mui/material/CardContent"
import CardActions from "@mui/material/CardActions"
import Collapse from "@mui/material/Collapse"
import Avatar from "@mui/material/Avatar"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import { red } from "@mui/material/colors"
import FavoriteIcon from "@mui/icons-material/Favorite"
import ShareIcon from "@mui/icons-material/Share"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import CloseIcon from "@mui/icons-material/Close"

import { ModuleContext } from "./module-context"
import useEntryData from "./use-entry-data"

const ExpandMore = styled((props) => {
    const { expand, ...other } = props
    return <IconButton {...other} />
})(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
    }),
}))

export default function ModuleCard() {
    const [expanded, setExpanded] = React.useState(false)
    const [collapsed, setCollapsed] = React.useState(true)
    const { module, setModule } = React.useContext(ModuleContext)

    const handleExpandClick = () => {
        setExpanded(!expanded)
    }

    const handleCloseClick = () => {
        setExpanded(false)
        setModule(null)
    }

    const handleCollapseClick = () => { }
    // module
    //     ? console.log(useEntryData(module.order[module.orderIndex]))
    //     : console.log("no module")

    return <></>
    // Disabling cards for modules v1
    // return (
    //     <Card
    //         sx={{
    //             maxWidth: 650,
    //             position: "fixed",
    //             bottom: "20px",
    //             right: "20px",
    //             // marginRight: "30px",
    //             marginLeft: "30px",
    //             zIndex: 100,
    //             display: module ? "block" : "none",
    //         }}
    //         raised={true}
    //     >
    //         <CardActions disableSpacing>
    //             <CardContent>
    //                 Current Module: {module ? module.name : `No active module`}
    //             </CardContent>
    //             <ExpandMore
    //                 expand={expanded}
    //                 onClick={handleExpandClick}
    //                 aria-expanded={expanded}
    //                 aria-label="show more"
    //             >
    //                 <ExpandMoreIcon />
    //             </ExpandMore>
    //             <IconButton
    //                 onClick={handleCloseClick}
    //                 aria-label="close module"
    //             >
    //                 <CloseIcon />
    //             </IconButton>
    //         </CardActions>
    //         <Collapse in={expanded} timeout="auto" unmountOnExit>
    //             <CardContent>
    //                 {module && (
    //                     <Typography>
    //                         Entry: {module.order[module.orderIndex]}
    //                     </Typography>
    //                 )}
    //             </CardContent>
    //         </Collapse>
    //     </Card>
    // )
}
